/**
 * File navigation.js.
 *
 * Handles toggling the off-canvas content (sidebar)
 */
jQuery(function ($) {
	adjustPageContentPosition();
	toggleOffCanvasContent();

	$(window).on('scroll', toggleScrollClass);
	$(window).on('resize', () => adjustPageContentPosition());

	/**
	 * Handles toggling the off-canvas content menu on button click
	 */
	function toggleOffCanvasContent() {
		let bsDefaults = {
			offset: true,
			overlay: true,
			width: '250px'
		};
		let bsMain = $('.bs-offset-main');
		let bsOverlay = $('.bs-canvas-overlay');

		// Opens off-canvas content when hamburger is clicked.
		$('[data-toggle="canvas"][aria-expanded="false"]').on('click', function () {
			let canvas = $(this).data('target');
			let opts = $.extend({}, bsDefaults, $(canvas).data());
			let prop = $(canvas).hasClass('bs-canvas-right') ? 'right' : 'left';

			if (opts.width === '100%') {
				opts.offset = false;
			}

			// Sets off-canvas width to opt.width
			$(canvas).css('width', opts.width);

			// Moves Main content left/right by width of off-canvas content (opt.width)
			if (opts.offset && bsMain.length) {
				bsMain.css('position', 'relative');
				bsMain.css(prop, opts.width);
			}

			// Sets aria attributes to true for both open & close buttons
			$(canvas + ' .bs-canvas-close').attr('aria-expanded', "true");
			$('[data-toggle="canvas"][data-target="' + canvas + '"]').attr('aria-expanded', "true");

			// Shows overlay if set to true
			if (opts.overlay && bsOverlay.length) {
				bsOverlay.addClass('show');
			}

			return false;
		});

		// Closes off-canvas content when overlay or close button is clicked.
		$('.bs-canvas-close, .bs-canvas-overlay').on('click', function () {
			let canvas = $('.bs-canvas');
			let aria;
			let canvasSide = $(canvas).hasClass('bs-canvas-right') ? 'right' : 'left';

			// Off-canvas content close button was clicked
			if ($(this).hasClass('bs-canvas-close')) {
				aria = $(this).add($('[data-toggle="canvas"][data-target="#' + canvas.attr('id') + '"]'));
			} else {
				aria = $('.bs-canvas-close, [data-toggle="canvas"]');
			}

			// Re-positions Main Content so it's no longer offset
			if (bsMain.length) {
				bsMain.css(canvasSide, '0');
			}

			// Hides off-canvas content
			canvas.css('width', '');
			aria.attr('aria-expanded', "false");

			// Hides overlay
			if (bsOverlay.length) {
				bsOverlay.removeClass('show');
			}

			return false;
		});
	}

	/**
	 * Toggles the a 'scrolling' class on an element whenever a certain threshold
	 * is reached. Typcially added to the body element for use with the navbar.
	 */
	function toggleScrollClass() {
		const scrollThreshold = 200;
		const targetElement = 'body';
		const scrollClass = 'scrolling';

		if ($(window).scrollTop() > scrollThreshold) {
			$(targetElement).addClass(scrollClass);
		} else {
			$(targetElement).removeClass(scrollClass);
		}
	}

	/**
	 * This sets the page padding-top to accomodate for the fixed navbar
	 */
	function adjustPageContentPosition(repositionHomepage = true) {
		let navBarHeight = $('.bs-offset-main > #masthead').outerHeight();
		const adminBarHeight = $('#wpadminbar').outerHeight() || 0;

		// Add wpadminbar height if logged into WP
		if ($(document.body).hasClass('admin-bar')) {
			navBarHeight += adminBarHeight;
		}

		// Reposition content on all pages
		$('.bs-offset-main').css('padding-top', navBarHeight);
		// $('.off-canvas-content > .simple-site-obit-single').css('padding-top', navBarHeight);

		// Reset position on homepage when repositionHomepage is false
		if (!repositionHomepage) {
			$('body.home > #page > .bs-offset-main').css('padding-top', navBarHeight);
		}
	}
});